var dataTemplate = {
    eChook: {
        binary: {
            variableThrottle: {
                name: "Throttle Type",
                op1: "Variable",
                op2: "On/Off",
                value: null,
                old: null,
                hidden: 0,
                changed: 0,
            },
            throttleOut: {
                name: "Throttle Output (PWM)",
                op1: "On",
                op2: "Off",
                value: null,
                old: null,
                hidden: 0,
                changed: 0,
            },
            throttleRamp: {
                name: "Throttle Ramp",
                op1: "On",
                op2: "Off",
                value: null,
                old: null,
                hidden: 0,
                changed: 0,
            },
            linearTempSensor: {
                name: "Temp Sensor Type",
                op1: "Linear",
                op2: "Thermistor",
                value: null,
                old: null,
                hidden: 0,
                changed: 0,
            },
        },
        bluetoothName: {
            calibratable: 1,
            value: null,
            old: null,
            hidden: 1,
            changed: 0,
        },
        transmitInterval: {
            title: "Data Transmit Interval",
            precision: 0,
            units: "ms",
            identifier: null,
            hidden: 1,
            calibratable: 1,
            calibration: {
                interval: {
                    name: "Interval",
                    units: "ms",
                    value: null,
                    floatIndex: 0,
                    changed: 0,
                    old: null,
                },
            },
        },
        referenceVoltage: {
            title: "Reference Voltage",
            precision: 2,
            units: "V",
            value: null,
            identifier: "V",
            calibratable: 1,
            calibration: {
                voltage: {
                    name: "Voltage",
                    units: "Volts",
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: null, // no calibration
                },
            },
        },
        speed: {
            title: "Speed",
            precision: 2,
            units: "m/s",
            value: null,
            identifier: "s",
            calibratable: 1,
            calibration: {
                magnets: {
                    name: "Magnets",
                    precision: 0,
                    value: null,
                    floatIndex: 1,
                    changed: 0,
                    old: null,
                },
                circumference: {
                    name: "Circumference",
                    unit: "meters",
                    precision: 2,
                    value: null,
                    floatIndex: 15,
                    changed: 0,
                    old: null,
                },
            },
        },
        rpm: {
            title: "Motor RPM",
            precision: 0,
            units: "RPM",
            value: null,
            identifier: "m",
            calibratable: 1,
            calibration: {
                magnets: {
                    name: "Magnets",
                    value: null,
                    floatIndex: 2,
                    changed: 0,
                    old: null,
                },
            },
        },
        current: {
            title: "Current",
            precision: 2,
            units: "A",
            value: null,
            identifier: "i",
            calibratable: 1,
            calibration: {
                multiplier: {
                    name: "Scaling Factor",
                    precision: 2,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 6,
                },
            },
        },
        voltage: {
            title: "Voltage (total)",
            precision: 2,
            units: "V",
            value: null,
            identifier: "v",
            calibratable: 1,
            calibration: {
                multiplier: {
                    name: "Scaling Factor",
                    precision: 2,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 4,
                },
            },
        },
        voltageLower: {
            title: "Volatge (lower)",
            precision: 2,
            units: "V",
            value: null,
            identifier: "w",
            calibratable: 1,
            calibration: {
                multiplier: {
                    name: "Scaling Factor",
                    precision: 2,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 5,
                },
            },
        },
        throttleInput: {
            title: "Throttle Input",
            precision: 2,
            units: "V",
            value: null,
            identifier: "t",
            calibratable: 1,
            calibration: {
                lowThreshold: {
                    name: "Low Threshold",
                    unit: "Volts",
                    value: null,
                    precision: 2,
                    changed: 0,
                    old: null,
                    floatIndex: 13,
                },
                highThreshold: {
                    name: "High Threshold",
                    unit: "Volts",
                    value: null,
                    precision: 2,
                    changed: 0,
                    old: null,
                    floatIndex: 14,
                },
            },
        },
        throttleActual: {
            title: "Throttle Actual",
            precision: 1,
            units: "%",
            value: null,
            identifier: "d",
            calibratable: 0,
            calibration: {
                multiplier: {
                    value: null, // Not used, but present to keep the object iterable in the template
                    precision: 1,
                    changed: 0,
                    old: null,
                    floatIndex: null,
                },
            },
        },
        temp3: {
            title: "Temperature eChook",
            precision: 0,
            units: "°c",
            value: null,
            identifier: "c",
            calibratable: 0,
            calibration: {
                placeholder: {
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: null, // no calibration
                },
            },
        },
        temp1: {
            title: "Temperature 1",
            precision: 0,
            units: "°c",
            value: null,
            identifier: "a",
            calibratable: 1,
            calibration: {
                A: {
                    name: "Cal A",
                    precision: 7,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 7,
                },
                B: {
                    name: "Cal B",
                    precision: 7,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 8,
                },
                C: {
                    name: "Cal C",
                    precision: 7,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 9,
                },
            },
        },
        temp2: {
            title: "Temperature 2",
            precision: 0,
            units: "°c",
            value: null,
            identifier: "b",
            calibratable: 1,
            calibration: {
                A: {
                    name: "Cal A",
                    precision: 7,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 10,
                },
                B: {
                    name: "Cal B",
                    precision: 7,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 11,
                },
                C: {
                    name: "Cal C",
                    precision: 7,
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: 12,
                },
            },
        },

        launchMode: {
            title: "Launch Mode Button",
            precision: 0,
            units: null,
            value: 0,
            identifier: "L",
            calibratable: 0,
            calibration: {
                placeholder: {
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: null, // no calibration
                },
            },
        },
        cycleView: {
            title: "Next Screen Button",
            precision: 0,
            units: null,
            value: 0,
            identifier: "C",
            calibratable: 0,
            calibration: {
                placeholder: {
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: null, // no calibration
                },
            },
        },
        gearRatio: {
            title: "Gear Ratio",
            precision: 2,
            units: null,
            value: null,
            identifier: "r",
            calibratable: 0,
            calibration: {
                placeholder: {
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: null, // no calibration
                },
            },
        },
        brakePressed: {
            title: "Brake Switch",
            precision: 0,
            units: null,
            value: 0,
            identifier: "B",
            calibratable: 0,
            calibration: {
                inverted: {
                    name: "Inverted",
                    value: null,
                    changed: 0,
                    old: null,
                    floatIndex: null, // no calibration
                },
            },
        },
    },
}

export {dataTemplate}